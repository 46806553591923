import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormFieldsComponent } from './layout/form-fields/form-fields.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableFilterComponent } from './tableFilter/table-filter.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { MenuSidebarComponent } from './menu-sidebar/menu-sidebar.component';
import { CustomPaginationComponent } from './custom-pagination/custom-pagination.component';
import { LanguageComponent } from './language/language.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CustomTableOptionComponent } from './custom-tableOption/custom-table-option.component';
import { ProfileComponent } from './profile/profile.component';
import { CapitalizeFirstLetterPipe } from './pipe/capitalize-first-letter.pipe';
import { PermissionComponent } from './permission/permission.component';
import { MatMenuModule } from '@angular/material/menu';
import { ChangePasswordComponent } from './profile/modal/change-password.component';
import { CustomTableFilterComponent } from './custom-table-filter/custom-table-filter.component';
import { PrintPayslipComponent } from './print-payslip/print-payslip.component';
import { SliceWordPipe } from './slice-word.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SelectDropDownModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule
  ],
  exports: [
    FormFieldsComponent,
    TableFilterComponent,
    MenuSidebarComponent,
    CustomPaginationComponent,
    LanguageComponent,
    CustomTableOptionComponent,
    ProfileComponent,
    CapitalizeFirstLetterPipe,
    PermissionComponent, 
    CustomTableFilterComponent,
    PrintPayslipComponent,
    SliceWordPipe
  ],
    
  declarations: [
    FormFieldsComponent,
    TableFilterComponent,
    MenuSidebarComponent, 
    CustomPaginationComponent,
    LanguageComponent, 
    CustomTableOptionComponent,
    ProfileComponent, 
    CapitalizeFirstLetterPipe, 
    PermissionComponent, 
    ChangePasswordComponent, 
    CustomTableFilterComponent, 
    PrintPayslipComponent, SliceWordPipe
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-US' }]
})
export class SharedModule { }
